<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>

        <v-card rounded="lg">
            <v-card-title>
                <span class="editbox">ADD SAINTS</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="closeDialog()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>


            </v-card-title>

            <v-card-text>
                <v-form>
                  <v-layout wrap justify-center px-2>
                   

                    <v-flex xs12 pr-2>
                      <span class="label">
                        Name of the saint
                      </span>
                      <v-text-field class="pt-2 text-des" style="font-size:14px"
                      v-model="saintName" outlined dense hide-details></v-text-field>
                  </v-flex>

                  <!-- <v-flex xs12 pt-3 pr-2>
                    <span class="label">
                      Feast Date 
                    </span>
                   
                    <v-text-field
                     class="pt-2 text-des" style="font-size:14px"
                    outlined
                    dense
                    hide-details
                    :value="formattedDate"
                    @click="dialog = true"
                    readonly
                  ></v-text-field>
                  
                  <v-dialog v-model="dialog" max-width="290">
                    <v-date-picker
                      v-model="feastDay"
                      @input="updateDate"
                    ></v-date-picker>
                  </v-dialog>
                </v-flex> -->
  
                <v-flex xs12 pt-3 pr-2>
                  <span class="label">Feast Date</span>
                  <v-text-field
                    class="pt-2 text-des"
                    style="font-size:14px"
                    outlined
                    dense
                    hide-details
                    :value="formattedDate" 
                    @click="dialog = true"
                    readonly
                  ></v-text-field>
              
                  <v-dialog v-model="dialog" max-width="290">
                    <v-date-picker v-model="feastDay" @input="updateDate"></v-date-picker>
                  </v-dialog>
                </v-flex>

                  <v-flex xs12 pt-3 pr-2>
                    <span class="label">
                     Description
                    </span>
                    <v-textarea class="pt-2 text-des" style="font-size:14px"
                    v-model="saintDescription" outlined dense hide-details></v-textarea>
                </v-flex>

                
                  </v-layout>
                   
                </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-btn outlined color="grey" text @click="closeDialog()"><span style="color: black;">Cancel</span>
                </v-btn>
                <v-btn color="primary" @click="validateInput()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
//import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
    components: {
        //ImageComp,
        // VueEditor,
    },
    data() {
        return {

 showSnackBar:false,
      timeout:5000,
      msg: "",

            dialog: false,
            // No initial date
            saintName: null,
            saintDescription: null,
           
            feastDay:null,



        };
    },

    computed: {
    formattedDate() {
      // Format the date for display in the text box as "Day Month"
      return this.feastDay
        ? new Date(this.feastDay).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long"
          })
        : '';
    }
  },
    methods: {
        closeDialog() {
            this.$emit("stepper", {
                ref: "addSaint",
                dialog: false,
            });
        },

        saveDialog(msg) {
            this.$emit("stepper", {
                ref: "addSaint",
                 dialog: false,
                msg: msg,
            });
        },
    //     updateDate(feastDay) {
    //   this.feastDay = feastDay;
    //   this.dialog = false; // Close the dialog after selecting the date
    // },
    updateDate(feastDay) {
      // Store the selected date in ISO format
      this.feastDay = new Date(feastDay).toISOString();
      this.dialog = false; // Close the date picker dialog
    },

    validateInput() {
 
  if (!this.saintName) {
    this.msg = "Please provide name of the saint";
    this.showSnackBar = true;
    return;
  } 
  else if (!this.feastDay) {
    this.msg = "Please select the feast date";
    this.showSnackBar = true;
    return;
  } 
  else if (!this.saintDescription) {
    this.msg = "Please provide short description";
    this.showSnackBar = true;
    return;
  } 

  else {
    this.itemadd();
  }
},

        // itemadd() {
        //   var data = {};
        //   data["saintName"] = this.saintName;
        //   data["saintDescription"] = this.saintDescription;
        //   data["feastDay"] = this.feastDay;
        //   axios({
        //     url: "/add/new/saint",
        //     method: "POST",
        //     data: data,
        //     headers: {
        //       token: localStorage.getItem("token"),
        //     },
        //   })
        //     .then((response) => {
        //       this.appLoading = false;
        //       if (response.data.status) {
        //         this.msg = response.data.msg;
        //     this.showSnackBar = true;
        //         this.dialog = false;
        //         this.saintName = null;
        //         this.saintDescription = null;
        //         this.feastDay = null;
            

                
               
        //         this.saveDialog(this.msg);
        //         // this.getData();
        //       } else {
        //         this.msg = response.data.msg;
        //     this.showSnackBar = true;
        //       }
        //     })
        //     .catch((err) => {
        //       this.appLoading = false;
        //       this.ServerError = true;
        //       console.log(err);
        //     });
        // },
      
        itemadd() {
      var data = {
        saintName: this.saintName,
        saintDescription: this.saintDescription,
        feastDay: this.feastDay // Send the date in ISO format to the backend
      };

      axios({
        url: "/add/new/saint",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token")
        }
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.saintName = null;
            this.saintDescription = null;
            this.feastDay = null;
            this.saveDialog(this.msg);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },


    },
    
  //   computed: {
  //   formattedDate() {
  //     // Only format and return the date if one is selected
  //     return this.feastDay ? new Date(this.feastDay).toLocaleDateString() : '';
  //   },
  // },
};
</script>