<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>

        <!-- Header Section -->
        <v-layout wrap justify-start class="mt-3">
          <v-flex xs12 sm4 md6 lg6 pt-2 align-center pb-3 class="headingChurch">Saints List</v-flex>
          <v-flex xs12 sm6 md4 lg4 pr-lg-0 pr-sm-3 pr-md-0 pr-0 >
            <v-text-field v-model="keyword" append-icon="mdi-magnify" :hide-details="true" label="Search by name" solo clearable dense flat
              outlined style="font-family:interregular"></v-text-field>
            </v-flex>
          <v-flex xs12 sm2 md2 lg2 pb-3 pl-0 pt-2 pt-lg-0 pt-sm-0 pt-md-0 text-end align-center><v-btn class="churchbtn" color="blue" style="color:white"
              @click="dialog = true">ADD SAINT</v-btn></v-flex>

          <v-dialog v-model="dialog" persistent max-width="800px">
            <AddSaint @stepper="winStepper" />
          </v-dialog>

        </v-layout>

       
            <!-- filter Section -->
            <!-- <v-layout wrap justify-start>
              <v-flex xs12 sm6 md4 lg4 pr-lg-0 pr-sm-2 pr-md-2 pr-0>
                <v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" solo clearable dense flat
                  outlined style="font-family:interregular"></v-text-field>
                </v-flex>
              </v-layout> -->

            <!-- table section -->
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-data-table :headers="headers" :items="saintlist" hide-default-footer class="elevation-1 text--black"
                  id="virtual-scroll-table">
                  <template v-slot:[`item.feastDay`]="{ item }">
                    <span>{{ formatDate1(item.feastDay) }}</span>
                  </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small color="primary" class="ml-1" @click="editSlider(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small color="red" class="ml-1" @click.stop="openDeleteDialog(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
             <!-- pagination -->
             <v-layout wrap justify-center pt-2>
              <v-flex xs12>
                <div class="text-center pb-5" v-if="pages > 0">
                  <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                    circle></v-pagination>
                </div>
              </v-flex>
            </v-layout>

        
      </v-flex>
    </v-layout>


    <!-- delete dialog -->
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            y?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img src="@/assets/images/calendar.png" width="70px" height="70px" class="dialog-icon" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn style="font-family: interBold; font-size: 13px; color: white;" color="#cf3a45"
            @click="deletedialog = false">Cancel</v-btn>
          <v-btn style="font-family: interBold; font-size: 13px; color: white;" color="blue darken-1"
            @click="confirmDelete">OK</v-btn>
        </v-card-actions>
      </v-card>




    </v-dialog>

    <!--edit dialog  -->
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT SAINT DETAILS</span>
          <v-spacer></v-spacer>

        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-center px-2>
              <v-flex xs12 pr-2>
                  <span class="label ">
                    Saint Name
                  </span>
                   <v-text-field class="pt-2 text-des" style="font-size:14px" v-model="editingitem.saintName"
                      outlined dense hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 pr-2 pt-3>
                <span class="label ">
                  Feast Day
                </span>
                 <v-menu ref="menu" style="font-size:14px"  class="pt-2 text-des" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="formattedDate" outlined dense hide-details v-bind="attrs" v-on="on"
                        readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="editDate" @input="updateDate" locale="en"></v-date-picker>
                  </v-menu>
            </v-flex>
              <v-flex xs12 pr-2 pt-3>
                <span class="label ">
                  Description
                </span>
                 <v-textarea class="pt-2 text-des" style="font-size:14px" v-model="editingitem.description"
                    outlined dense hide-details></v-textarea>
            </v-flex>
           


             </v-layout>
           
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="closeDialog()"><span style="color: black;">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="validateInputEdit(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import AddSaint from './addSaint';
import axios from 'axios';
export default {
  components: {

    AddSaint

  },
  data() {
    return {
      showSnackBar:false,
      timeout:5000,
      msg: "",
      appLoading: false,
      dialog: false,
      deletedialog: false,
      menu: false, // Controls the visibility of the menu
      editDate: '', // Initialize as an empty string
      saintlist: [],
      editdialog: false,
      editingitem: {
        feastDay: '',// Initialize with your date string
      },
      keyword: "",
      headers: [
        { text: "Name", value: "saintName", width: "140px" },
        { text: "Description", value: "description", width: "300px" },
        { text: "Date", value: "feastDay", width: "110px" },
        // { text: "Diocese", value: "diocese.name", width: "10px" },
        // // { text: "Parish Type", value: "parishType.name", width: "90px" },
        // { text: "Country", value: "address.country", width: "10px" },
        { text: "Actions", value: "_id", width: "5px", }

      ],

      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 8,


    };
  },
  computed: {
    formattedDate: {
    get() {
      // Display the date as "Day Month" (e.g., "28 November")
      return this.editDate ? this.formatDateForDisplay(this.editDate) : '';
    },
    set(newValue) {
      // Set the date value (backend format)
      this.editDate = newValue;
      this.editingitem.feastDay = this.parseDate(newValue);
    }
  }
  },
  watch: {
    editingitem: {
      deep: true,
      handler() {
        if (this.editingitem && this.editingitem.feastDay) {
          this.editDate = this.formatDate(new Date(this.editingitem.feastDay));
        }
      }
    },
    keyword() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData(); // Fetch data when component mounts

  },



  methods: {
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    // formatDate1(dateString) {
    //   const date = new Date(dateString);
    //   return date.toLocaleDateString('en-GB').replace(/\//g, '-');
    // },
    formatDate1(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', { month: 'long', day: 'numeric' });
},
formatDateForDisplay(date) {
    // Format date as "Day Month" (e.g., "28 November")
    const options = { day: 'numeric', month: 'long' };
    return new Date(date).toLocaleDateString('en-GB', options);
  },
  
  formatDate(date) {
    if (!date) return '';
    // Ensure date is formatted to 'YYYY-MM-DD' for backend
    return date instanceof Date ? date.toISOString().slice(0, 10) : date;
  },

  parseDate(dateString) {
    // Convert 'YYYY-MM-DD' format to a Date object
    return new Date(dateString).toISOString();
  },
  
  updateDate(date) {
    this.menu = false; // Close the menu after selecting the date
    this.editDate = date;
    this.editingitem.feastDay = this.parseDate(date); // Format for backend
  },
   
    winStepper(item) {
      if (item.ref == 'addSaint') {
        this.dialog = item.dialog;
        this.getData();
       
        if (item.msg) { // Check if there's a message
            this.msg = item.msg;
            this.showSnackBar = true; // Display the snackbar
        }
      }
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/get/saint/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
        
          limit: this.limit,
          page: this.currentPage,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.saintlist = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;

    },

    
    validateInputEdit() {
 
 if (!this.editingitem.saintName) {
   this.msg = "Please provide name of the saint";
   this.showSnackBar = true;
   return;
 } 
 else if (!this.editingitem.feastDay) {
   this.msg = "Please select the feast date";
   this.showSnackBar = true;
   return;
 } 
 else if (!this.editingitem.description) {
   this.msg = "Please provide short description";
   this.showSnackBar = true;
   return;
 } 

 else {
   this.edit();
 }
},
edit() {
  this.appLoading = true;
  var user = {};
  user["saintId"] = this.editingitem._id;
  user["saintName"] = this.editingitem.saintName;
  user["saintDescription"] = this.editingitem.description;

  // Pass date in 'YYYY-MM-DD' format to the backend
  user["feastDay"] = this.formatDate(this.editingitem.feastDay);

  axios({
    url: "/update/saint",
    method: "POST",
    data: user,
    headers: {
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status) {
        this.editdialog = false;
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.getData();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["saintId"] = r._id;
      axios({
        url: "/delete/saint",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>

<style scoped>
.dialog-card {
  font-family: interbold;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
  animation: pulse 1s infinite alternate;
}

.dialog-button {
  min-width: 120px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.7;
  }

  to {
    transform: scale(1.1);
    opacity: 1;
  }
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  background-color: white;
  table-layout: fixed;
  /* Fixes column widths to prevent stretching */
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: white;
  font-family: interbold;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.table td {
  font-family: interregular;
  font-size: 15px;
}

.table-row:hover {
  background-color: #eff2f6;
}

.actions-column {
  text-align: center;
  /* Center-aligns the action buttons */
}
</style>